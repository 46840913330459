<template>
  <footer class="text-sm text-center py-8 mt-6">
    <p class="text-gray-500">
      © 2024 Datex Tecnologías. Todos los derechos reservados.
    </p>
    <router-link to="/terms" class="text-blue-500 hover:text-blue-300 mx-2">
      Términos y Condiciones
    </router-link>
    <router-link to="/privacy" class="text-blue-500 hover:text-blue-300 mx-2">
      Aviso de Privacidad
    </router-link>
  </footer>
</template>
