import numeral from "numeral";
const filters = {
  DollarFilter: (value) =>
    value ? numeral(value).format("($ 0,0.00)") : `$ 0`,
  DollarFilterNoBS: (value) =>
    value ? numeral(value).format("($0,0.00)") : `$ 0`,
  PercentFilter: (value) => (value ? `${Number(value).toFixed(2)}%` : `0%`),
};

export default filters;
