const AccountStatus = Object.freeze({
  Null: -100,
  Added: 0,
  VerifiedMail: 1,
  AwaitingPayment: 2,
  Paid: 3,
  Process: 4,
  Active: 5,
  Inactive: 6,
  Suspend: 7,
  Canceled: 8,
});

const TypeDevice = Object.freeze({
  Null: -100,
  Any: 100,
  None: 0,
  PC: 1,
  iPhone: 2,
  iPad: 3,
  Android: 4,
  AndroidTablet: 5,
  WindowsPhone: 6,
  WindowsStore: 7,
  Server: 8,
  Web: 9,
  MAC: 10,
});

const AdmixProduct = Object.freeze({
  Null: -100,
  Any: 100,
  AdmixManagement: -1,
  AdmixNULL: 0,
  AdmixCFDi: 1,
  AdmixAutoFactura: 2,
  AdmixERP: 3,
  AdmixTPV: 4,
  AdmixMKT: 5,
  AdmixBCO: 6,
  AdmixContabilidad: 7,
  AdmixIQ: 8,
  AdmixCloud: 9,
  AdmixDescarga: 10,
  AdmixUpdater: 11,
  AdmixRH: 12,
  AdmixTeam: 13,
  AdmixTimbradoNomina: 14,
  AdmixTimbradoClient: 15,
  AdmixTimbradoRetencion: 16,
});

export default {
  AccountStatus,
  TypeDevice,
  AdmixProduct,
};
