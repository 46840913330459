import { createRouter, createWebHistory } from "vue-router";
import SignIn from "../components/Onboarding/SignIn";
import wsCompany from "@/services/modules/wsCompany";
import enums from "@/services/enums/enums";

const routes = [
  {
    path: "/",
    name: "signin",
    component: SignIn,
    meta: {
      title: "ZA | Iniciar sesión",
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../components/Onboarding/SignUp"),
    meta: {
      title: "ZA | Crear cuenta",
    },
  },
  {
    path: "/signupsuccess",
    name: "signupsuccess",
    component: () => import("../components/Onboarding/SignUpSucces"),
    meta: {
      title: "",
    },
  },
  {
    path: "/password",
    name: "password",
    component: () => import("../components/Onboarding/ChangePassword"),
    beforeEnter(to, from) {
      if (from.name === "otp") {
        const hasCode =
          localStorage.getItem("code") != null
            ? localStorage.getItem("code").length === 6
            : false;
        return hasCode;
      }
      return false;
    },
    meta: {
      title: "",
    },
  },
  {
    path: "/passwordsuccess",
    name: "passwordsuccess",
    component: () => import("../components/Onboarding/ChangeSuccess"),
    meta: {
      title: "",
    },
  },
  {
    path: "/forgot",
    name: "forgot",
    component: () => import("../components/Onboarding/ForgotPassword"),
    meta: {
      title: "ZA | Restablecer contraseña",
    },
  },
  {
    path: "/otp",
    name: "otp",
    component: () => import("../components/Onboarding/OtpVerification"),
    beforeEnter(to, from) {
      if (from.name === "forgot") {
        const hasRecovery =
          localStorage.getItem("recovery") != null
            ? localStorage.getItem("recovery") === "1"
            : false;
        return hasRecovery;
      } else if (from.name === "signup") {
        const hasUserEmail =
          localStorage.getItem("userEmail") != null ? true : false;
        return hasUserEmail;
      }
      return false;
    },
    meta: {
      title: "",
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../components/Xml/Views/Legal/TermsView"),
    meta: {
      title: "",
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../components/Xml/Views/Legal/PrivacyView"),
    meta: {
      title: "",
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/components/Xml/Home/HomeView"),
    beforeEnter(to, from) {
      const hasDevKey = localStorage.getItem("devkey") != null;
      if (hasDevKey) {
        const devkey = localStorage.getItem("devkey");
        const userId = localStorage.getItem("userId") ?? 0;
        const hasCompany = wsCompany
          .HasCompany(
            devkey,
            enums.TypeDevice.Web,
            enums.AdmixProduct.AdmixManagement,
            userId
          )
          .then((res) => {
            if (res.result) {
              // console.log(res);
              if (res.response.ListCompanies) {
                localStorage.setItem(
                  "companies",
                  JSON.stringify(res.response.ListCompanies)
                );
                return true;
              }
            }
            return false;
          });
        return hasCompany;
      }
      return hasDevKey;
    },
    meta: {
      title: "ZA | Dashboard",
    },
  },
  {
    path: "/emision",
    name: "emision",
    component: () => import("@/components/Xml/Emision/EmisionVue"),
    meta: {
      title: "ZA | Emisión",
    },
  },
  {
    path: "/recepcion",
    name: "recepcion",
    component: () => import("@/components/Xml/Receipt/ReceiptVue"),
    meta: {
      title: "ZA | Recepción",
    },
  },
  {
    path: "/clientes",
    name: "clientes",
    component: () => import("@/components/Xml/Customers/CustomersVue"),
    meta: {
      title: "ZA | Clientes",
    },
  },
  {
    path: "/proveedores",
    name: "proveedores",
    component: () => import("@/components/Xml/Providers/ProvidersVue"),
    meta: {
      title: "ZA | Proveedores",
    },
  },
  {
    path: "/productos",
    name: "productos",
    component: () => import("@/components/Xml/Products/ProductsVue"),
    meta: {
      title: "ZA | Productos",
    },
  },
  {
    path: "/nomina",
    name: "nomina",
    component: () => import("@/components/Xml/Payroll/PayrollVue"),
    meta: {
      title: "ZA | Nómina",
    },
  },
  {
    path: "/fiscal",
    name: "fiscal",
    component: () => import("@/components/Xml/Fiscal/FiscalVue"),
    meta: {
      title: "ZA | Fiscal",
    },
  },
  {
    path: "/onboarding",
    name: "onboarding",
    component: () => import("../components/Onboarding/OnBoarding"),
    beforeEnter(to, from) {
      const devkey = localStorage.getItem("devkey");
      if (devkey) {
        return wsCompany.HasCompany(devkey, 9, -1).then((res) => {
          // console.log(res);
          if (res.result) {
            if (res.response.ListCompanies) {
              if (res.response.ListCompanies.length == 0) {
                return true;
              }
            }
          }
          if (res.response.Error.Code === "GGE01") {
            return true;
          }
          return false;
        });
      }
      return false;
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../components/Settings/SettingsView"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../components/ProfileView"),
  },
  {
    path: "/playground",
    name: "playground",
    component: () => import("../components/PlaygroundView"),
  },
  {
    path: "/:catchAll(.*)",
    name: "error",
    component: () => import("../components/Error/NotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to);
  document.title = to.meta.title;
  next();
});

export default router;
